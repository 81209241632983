/* Standard-Schriftfarbe (heller Modus) */
.player-profile {
    color: black; /* Schriftfarbe im hellen Modus */
  }
  
  /* Dark Mode (wenn der Dark Mode aktiv ist) */
  @media (prefers-color-scheme: dark) {
    .player-profile {
      color: white; /* Schriftfarbe im Dark Mode */
    }
  }
  
  /* Dark Mode spezifische Klasse (falls du eine eigene Dark Mode Logik hast) */
  .player-profile.dark-mode {
    color: black; /* Schrift im Dark Mode weiß */
  }
  